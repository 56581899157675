.container {
  border: 1px solid #ffac06 !important;
  border-radius: 7px !important;
}

.highlight_img {
  width: 93px;
  height: 85px;
}

.ctaContainer {
  background-color: #ffac06;
}

.lockedImg {
  position: absolute;
  left: 0;
  width: 78px;
  height: 78px;
}
.title {
  font-size: calc(1vw * 1);
}

.description {
  font-size: calc(1vw * 0.8);
}

.logo {
  width: 7vw;
}

@media screen and (max-width: 1440px) {
  .title {
    font-size: calc(1vw * 1.2);
  }

  .description {
    font-size: calc(1vw * 0.9);
  }

  .logo {
    width: 9vw;
  }
}

@media screen and (max-width: 780px) {
  .title {
    font-size: calc(3vw * 1.2);
  }

  .description {
    font-size: calc(3vw * 0.9);
  }

  .logo {
    width: 20vw;
  }
}
