.subSectionWrapper {
  display: flex;
  height: 38px;
  gap: 12px;
  align-items: center;
  cursor: pointer;
  padding: 0px 16px;
  border-left: 2px solid transparent;
}

.subSectionTitle {
  color: rgba(39, 37, 34, 0.85);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  white-space: nowrap;
}

.activeSubSectionWrapper {
  border-left: 2px solid var(--primary-color);
  background-color: var(--bg-color);
}

.activeSubSectionTitle {
  color: var(--primary-color);
}

.sideMenuIcon {
  width: 22px !important;
  height: 22px !important;
}
