.headingText {
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
}

.heading {
  padding-bottom: 32px;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  align-items: center;
  background: #fff;
}

.backIcon {
  color: rgba(0, 0, 0, 0.85);
  width: 20px;
  height: 20px;
  margin-right: 16px;
  cursor: pointer;
}
@media only screen and (max-width: 600px) {
  .heading {
    padding-bottom: unset;
    padding: 20px 18px;
    border-bottom: 1.5px solid #e7e6e4;
    background: #fff;
  }
  .headingText {
    font-size: 18px;
  }
}
